
import { Component, Vue } from 'vue-property-decorator'
import TheCars from '@/components/car/TheCars.vue'

@Component({
  components: {
    TheCars
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class CarsPage extends Vue {
}
