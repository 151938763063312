
import '@/assets/styles/imports/cars.sass'
import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'
import router from '@/router'
import { VehicleInterface } from '@/interfaces/vehicle'
import TheCarsField from '@/components/car/TheCarsField.vue'
import TheCarsTable from '@/components/car/TheCarsTable.vue'
import Pagination from '@/components/_tools/Pagination.vue'

@Component ({
  components: {
    TheCarsField,
    TheCarsTable,
    Pagination
  }
})

export default class Cars extends Vue {
  pageLength = 40
  cars: Array<VehicleInterface.Vehicle> = []
  query = ''
  count = 0
  page = 1
  pages = 0

  refreshPage (page: number) {
    this.getCars(this.query, page)
    window.scrollTo(0, 0)
  }

  getStartCars () {
    if (this.$route.query.page) this.page = +this.$route.query.page
    if (this.$route.query.query) this.query = `${this.$route.query.query}`
    this.getCars(this.query, this.page)
  }

  async getCars (query= '', page = 1) {
    const params: VehicleInterface.Request = {
      skip: this.pageLength * (page - 1),
      take: this.pageLength,
      searchPattern: query
    }
    const data = await api.vehicle.getVehicle(params)
    this.cars = data.items
    this.pagination(data.itemsCount, page)
  }

  async searchCars (query: string) {
    const page = 1
    this.query = query
    if (query) router.push({ query: { query, page: `${page}` }}).catch(console.dir)
    else router.push({ query: {}}).catch(console.dir)
    await this.getCars(query, page)
  }

  pagination (itemsCount: number, page: number) {
    this.count = itemsCount
    this.pages = Math.ceil(itemsCount / this.pageLength)
    if (page <= this.pages && page > 0) return this.page = page
    if (page < 1) return this.page = 1
    this.page = this.pages
    this.getCars(this.query, this.page)
  }

  get paginationQuery () {
    if (this.query) return new URLSearchParams({ query: this.query }).toString()
    return ''
  }

  created () {
    this.getStartCars()
  }
}
