
import '@/assets/styles/tools/pagination.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop(String) routerName!: string
  @Prop(Number) currentPage!: number
  @Prop(Number) countPages!: number
  @Prop(String) query!: string

  getStartCount () {
    if (this.currentPage < 10) return 1
    if (this.currentPage - 5 > 0) return this.currentPage - 5
    return 1
  }

  getEndCount () {
    if (this.currentPage + 10 > this.countPages) return this.countPages
    if (this.currentPage + 5 > this.countPages) return this.countPages
    return this.currentPage + 5
  }

  eventMove (page: number) {
    this.$emit('move', page)
  }

  getRoute (page: number): string {
    const query = this.query ? `&${this.query}` : ''
    return `${ this.$route.params.lang ? '/' + this.$route.params.lang : '' }/${this.routerName}?page=${page}${query}`
  }

  get pages () {
    const pages = []
    if (this.countPages < 15) {
      for (let i = 1; i <= this.countPages; i++) { pages.push(i) }
      return pages
    }
    const start = this.getStartCount()
    const end = this.getEndCount()
    if (start > 4) pages.push(1,2,3,'...')
    for (let i = start; i <= end; i++) { pages.push(i) }
    if (end < this.countPages - 2) {
      pages.push('...')
      for (let i = this.countPages - 2; i <= this.countPages; i++) { pages.push(i) }
    }
    return pages
  }
}
