
import '@/assets/styles/imports/patches.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VehicleInterface } from '@/interfaces/vehicle'

@Component
export default class CarsTable extends Vue {
  @Prop(Array) readonly cars!: Array<VehicleInterface.Vehicle>

  headers = ['type','brand','model','engine','ecu']

  getLink (car: VehicleInterface.Vehicle) {
    return {
      name: 'Prices',
      params: { lang: this.$route.params.lang },
      query: {
        type: car.type.id,
        brand: car.brand.id,
        model: car.model.id,
        engine: car.engine.id,
        ecu: car.ecu.id
      }
    }
  }
}
