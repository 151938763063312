
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CarsField extends Vue {
  @Prop(String) readonly value!: string

  query = ''
  prevSearchText: string | null = null

  eventInputText () {
    if (this.prevSearchText === this.query.trim()) return
    const startQuery = this.query.trim()
    setTimeout(() => { this.checkMatchSearchString(startQuery) }, 400)
  }

  checkMatchSearchString (startQuery: string) {
    if (startQuery === this.prevSearchText) return
    if (startQuery !== this.query.trim()) return
    this.prevSearchText = this.query.trim()
    this.$emit('new-text', startQuery)
  }

  mounted () {
    this.query = this.value
  }
}
